<template>
  <div>
    <div class="home_pic">
      <div class="home_title">
        <img class="home_logo" src="../../assets/homeLogo.png" alt="" />
        <div class="home_cn">{{ title }}</div>
        <div class="home_en">{{ entitle }}</div>
        <img class="home_wavy" src="../../assets/wavyline.png" alt="" />
      </div>
    </div>
    <div class="home_probox">
      <div
        v-for="(item, index) in systemList"
        :key="index"
        class="home_pro home_bg"
        @click="toDesignatedSystem(item)"
        :style="{ background: 'url(' + item.backgroundImgUrl + ')  no-repeat' }"
      >
        <div>{{ item.displayName }}</div>
        <div v-if="item.status === 0">即将上线</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "金恪会员系统",
      entitle: "J&K INVESTMENT GROUP",
      systemList: [],
      isFromOtherRouter: false
    };
  },
  created() {
    this.getSystemList();
  },
  mounted() {
    setTimeout(() => {
      if (this.isFromOtherRouter) return;
      const merchantId = this.$store.state.merchantId;
      if (merchantId) {
        const redirectUrl = localStorage.getItem("redirectUrl");
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      }
    }, 1000);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.isFromOtherRouter = !!from.name;
    });
  },
  methods: {
    getSystemList() {
      this.$service.get("/mc/api/system/list").then(res => {
        this.systemList = res.data.data;
      });
    },
    toDesignatedSystem(item) {
      if (item.status === 0) {
        return;
      }
      this.$store.commit("updateMerchantId", item.merchantId);
      this.$store.commit("updateAppId", item.appId);
      this.$router.push({
        name: "VipViewer"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.home_pic {
  width: 100%;
  background: url("../../assets/homeBack.png") no-repeat;
  background-size: 100%;
  padding: 0.44rem 0.2rem 0.22rem;
  .home_title {
    width: 100%;
    height: 1.86rem;
    border-radius: 0.1rem;
    border: 0.01rem solid rgba($color: #ffffff, $alpha: 0.5);
    text-align: center;
    .home_logo {
      width: 0.36rem;
      margin: 0.2rem 0 0.16rem 0;
    }
    .home_cn {
      font-size: 0.28rem;
      font-weight: 400;
      color: #ffffff;
    }
    .home_en {
      margin: 0.06rem 0 0 0;
      font-size: 0.14rem;
      font-weight: 400;
      color: #ffffff;
      letter-spacing: 3px;
    }
    .home_wavy {
      width: 0.48rem;
    }
  }
}
.home_probox {
  padding: 0.1rem 0.2rem;
  .home_pro {
    width: 100%;
    height: 1.6rem;
    margin: 0.1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.22rem;
    font-weight: 600;
    color: #ffffff;
    text-shadow: 0px 0.01rem 0.03rem rgba(0, 0, 0, 0.3);
  }
  .home_bg {
    background-size: 100% !important;
  }
  // .home_bg0 {
  //   background: url("../assets/healthCare.png") no-repeat;
  //   background-size: 100%;
  // }
  // .home_bg1 {
  //   background: url("../assets/hotel.png") no-repeat;
  //   background-size: 100%;
  // }
  // .home_bg2 {
  //   background: url("../assets/farm.png") no-repeat;
  //   background-size: 100%;
  // }
  // .home_bg3 {
  //   background: url("../assets/xingfutong.png") no-repeat;
  //   background-size: 100%;
  // }
  // .home_bg4 {
  //   background: url("../assets/employeeTreasure.png") no-repeat;
  //   background-size: 100%;
  // }
}
</style>